<template>
  <section class="proof-of-bank">
    
    <h2 class="proof-of-bank__title headline-small">
      {{$t('pob.title')}}
    </h2>
    
    <div class="proof-of-bank__content body-text-large">
      <p v-text="$t('pob.document_explanation')"/>
      <ul>
        <li>{{$t('pob.driver_licence')}}</li>
        <li>{{$t('pob.passport')}}</li>
        <li>{{$t('pob.other')}}</li>
      </ul>
      <p v-text="$t('pob.photo_warning')" />
    </div>

    <!-- Bank document photo -->
    <h3 class="subheadline-medium mb-s8" v-text="$t('pob.bank_statement')" />
    <div class="flex items-center justify-around pb-s24">
      <div>
        <div class="proof-of-bank__image-container" :class="{'--has-image': hasBankImage}">
          <img
            v-if="hasBankImage"
            class="proof-of-bank__uploaded-image"
            :src="bankImgSrc"
            alt=" "
          />
        </div>
      </div>
      <div>
        <input 
          id="uploadedBank" 
          ref="uploadedBank" 
          name="uploadedBank" 
          data-testId="uploadedBank" 
          data-lpignore="true" 
          class="hidden" 
          type="file" 
          accept="image/*" 
          capture="camera" 
          test
          @change="handleUploadedBank" 
        />
        <ButtonV2 
          class="proof-of-bank__upload-btn"
          size="medium"
          wide
          data-testId="uploadedBankButton" 
          testId="btn-pobchange"
          :label="bankImgFile ? $t('pob.change') : $t('pob.select_image')"
          :version="bankImgFile ? 'secondary' : 'primary'"
          @onClick="handleBankImage"
        />
      </div>
    </div>

    <hr class="my-s28" />

    <!-- Selfie with ID -->
    <h3 class="subheadline-medium mt-s28 pt-s24 mb-s8" v-text="$t('pob.selfie_document')" />
    <div class="flex items-center justify-around mb-s40">
      <div>
        <div class="proof-of-bank__image-container" :class="{'--has-image': hasSelfieImage}">
          <img
            v-if="hasSelfieImage"
            class="proof-of-bank__uploaded-image"
            :src="selfieImgSrc"
            alt=" "
          />
        </div>
      </div>
      <div>
        <input 
          id="uploadedSelfie" 
          ref="uploadedSelfie" 
          name="uploadedSelfie" 
          data-testId="uploadedSelfie" 
          data-lpignore="true" 
          class="hidden" 
          type="file" 
          accept="image/*" 
          capture="camera" 
          test
          @change="handleUploadedSelfie" 
        />
        <ButtonV2 
          class="proof-of-bank__upload-btn"
          size="medium"
          data-testId="uploadedSelfieButton" 
          wide
          :label="selfieImgFile ? $t('pob.change') : $t('pob.select_image')"
          testId="btn-img-upload"
          :version="selfieImgFile ? 'secondary' : 'primary'"
          @onClick="handleSelfieImage"
        />
      </div>
    </div>
    
    <ButtonV2 
      :inactive="!isValid"
      class="mt-s16 mr-s8"
      version="primary"
      size="medium"
      wide
      :label="$t('pob.submit')"
      testId="btn-pobsubmit"
      @onClick="submitDocuments"
    />

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { ButtonV2 } from '@/components/misc';

export default {
  name: 'KycProofOfBank',
  
  components: {
    ButtonV2,
  },
  
  data() {
    return {
      bankImgSrc: null,
      bankImgFile: null,

      selfieImgSrc: null,
      selfieImgFile: null,
    };
  },

  mounted() {
    this.$store.dispatch('events/track', {
      event: 'PROOF_OF_BANK_VIEWED',
    });
  },
  
  computed: {
    ...mapGetters('user', ['getRequiredProofOfBankingId']),
    hasBankImage() {
      return !!this.bankImgSrc;
    },
    hasSelfieImage() {
      return !!this.selfieImgSrc;
    },
    isValid() {
      return this.bankImgFile && this.selfieImgFile;
    },
  },

  methods: {
    ...mapActions('user', ['uploadProofOfBankFile']),
    ...mapActions('ui', ['setIsOverlayLoading', 'showPrompt']),

    handleBankImage() {
      this.$refs.uploadedBank.click();
    },
    
    handleUploadedBank() {
      this.bankImgFile = this.$refs.uploadedBank.files[0];
      
      if(this.bankImgFile) {
        this.bankImgSrc = URL.createObjectURL(this.bankImgFile);
      }
    },

    handleSelfieImage() {
      this.$refs.uploadedSelfie.click();
    },
    
    handleUploadedSelfie() {
      this.selfieImgFile = this.$refs.uploadedSelfie.files[0];
      
      if(this.selfieImgFile) {
        this.selfieImgSrc = URL.createObjectURL(this.selfieImgFile);
      }
    },

    async submitDocuments() {
      try {
        this.setIsOverlayLoading(true);

        this.$store.dispatch('events/track', {
          event: 'PROOF_OF_BANK_SUBMITTED',
        });

        await this.uploadProofOfBankFile({
          id: this.getRequiredProofOfBankingId,
          bank_document: this.bankImgFile, 
          selfie_with_document: this.selfieImgFile
        });

        this.showPrompt({
          title: this.$t('pob.after_title'),
          message: this.$t('pob.after_text'),
          type: 'success',
        });

        this.showModal('KycStatus');
      } catch(e) {
        await this.showError(e);
        this.hideModal();
      } finally {
        this.setIsOverlayLoading(false);
      }
    }
  },
};
</script>

<style scoped>
.proof-of-bank {
  @apply 
    flex 
    flex-col 
    justify-between 
    flex-1
    self-center
    w-screen 
    max-w-sm 
    p-4;
  max-width: 450px;
}
.proof-of-bank__title {
  @apply mb-s24;
}
.proof-of-bank__content p {
  @apply mb-s24;
}
.proof-of-bank__content ul {
  @apply mb-s24 pl-s16 list-disc;
}
.proof-of-bank__content li {
  @apply mb-s2;
}
.proof-of-bank__take-photo {
  @apply flex flex-col items-center;
}
.proof-of-bank__image-container {
  @apply 
    p-s8
    flex
    items-center
    justify-center
    relative;
  width: 70px;
  height: 75px;
}
.proof-of-bank__image-container img {
  @apply max-w-full;
}
.proof-of-bank__image-container::after {
  content: '';
  @apply 
    absolute
    bg-no-repeat
    bg-contain
    bg-center
    w-full
    h-full;
  background-image: url('~@/assets/img/take-picture-initial-light.png');
}
.proof-of-bank__image-container.--has-image::after {
  background-image: url('~@/assets/img/take-picture-uploaded-light.png');
}
.dark .proof-of-bank__image-container::after {
  background-image: url('~@/assets/img/take-picture-initial-dark.png');
}
.dark .proof-of-bank__image-container.--has-image::after {
  background-image: url('~@/assets/img/take-picture-uploaded-dark.png');
}
.proof-of-bank__upload-btn {
  width: 160px;
}
</style>
